import * as React from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from "react-redux";
import { Button, Row, Col, Table, InputGroup, Form } from "react-bootstrap";
import { FaPlusCircle, FaArrowCircleLeft, FaTrashAlt } from 'react-icons/fa';
import { Link, RouteComponentProps } from "react-router-dom";
import Swal from 'sweetalert2'
import Search from '../autocomplete/AutoCompleteUser'
import { InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import * as SearchEmployeeStore from '../../store/employee/SearchEmployee'
import * as Models from '../../models/employee/SearchEmployee'
import { ApplicationState } from "../../store";

import * as CreateEmployeeStore from '../../store/employee/CreateEmployee'
import * as CreateModels from '../../models/employee/CreateEmployee'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'

import * as ConditionStore from '../../store/autocomplete/ConditionSearch'
import * as ConditionModels from '../../models/autocomplete/ConditionSearch';
import DatePicker from "react-datepicker";

import { formatDateYYYYMMDD, formatDocDate, parseJwt } from '../../modules/Common';
import { getMonth, getYear } from "date-fns";
import { getRoleMasterEmployee } from "data/MasterEmployee/repositories/MasterEmployee.repository";
import { RoleMasterEmployeeResponse } from "data/MasterEmployee/schemas/MasterEmployee.shema";
import CreatableSelect from 'react-select/creatable';

const validDateFrom = new Date()
const validDateTo = new Date("9999/12/31")

const inititem = {
    position_code: "",
    position_name: "",
    emp_id: "",
    emp_name: "",
    valid_from: `${validDateFrom.getFullYear()}${(validDateFrom.getMonth() + 1).toString().padStart(2, '0')}${validDateFrom.getDate().toString().padStart(2, '0')}`,
    valid_to: `${validDateTo.getFullYear()}${(validDateTo.getMonth() + 1).toString().padStart(2, '0')}${validDateTo.getDate().toString().padStart(2, '0')}`,
    is_delegate: "",
    delegate_from_emp_id: "",
    date_from: new Date(),
    date_to: new Date("9999/12/31")
};

const initSelect = {
    code: "",
    name: "",
    display: "",
};

type Opstion = {
    value: string
    label: string
}

// รับค่าจากหน้าหลัก
type Props = {};
type ComposedProps = Props & RouteComponentProps;
// redux
type searchemployeeProps = Models.SearchPositionEmployeeState & typeof SearchEmployeeStore.actionCreators;
type createemployeeProps = CreateModels.CreatePositionEmployeeState & typeof CreateEmployeeStore.actionCreators;
type rescreateemployeeProps = CreateModels.ResCreatePositionEmployeeState & typeof CreateEmployeeStore.actionCreators
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators
type ConditionProps = {
    conditionSearch: ConditionModels.GetConditionState & typeof ConditionStore.actionCreators;
};
class AddPositionEmployee extends React.Component<searchemployeeProps & ComposedProps & createemployeeProps & rescreateemployeeProps & AppStateProps & ConditionProps, any, { response: any, formdata: Array<Models.FormDataEmployeeState>, checkReadonly: boolean, company?: string, isready: boolean, userId: string }> {
    constructor(props: any) {
        super(props);
        this.state = {
            rows: [inititem],
            autocomplete: [],
            positionCode: '',
            positionName: '',
            isready: true,
            responsee: '',
            formdata: [],
            isCreate: true,
            checkReadonly: false,
            valueSelectUse: [initSelect],
            company: undefined,
            userId: undefined,
            roleMasterEmployee: undefined
        };
        this.handlechageAutocomplete = this.handlechageAutocomplete.bind(this)
    }

    componentDidMount() {
        const { state } = this.props.location
        var company = this.props.company
        this.props.updateUrl(this.props.location.pathname)

        if (state && this.state.isready == true && company) {
            this.setState({
                isready: false,
                positionCode: state.positioncode,
                positionName: state.positionname,
                checkReadonly: true,
                company: company
            })
            this.props.requestsearchemployee(true, "POST", state.positioncode, company)
        } else if (this.state.isready == true && company) {
            this.setState({
                company: company
            })
        }

        var ls: any = localStorage.getItem("WF_APPLICATION");
        var jls: any = JSON.parse(ls)
        var access_token = parseJwt(jls.access_token)
        var extra = JSON.parse(access_token.extra)

        if (extra.citizen_id) {
            this.setState({ userId: extra.citizen_id })
        } else if (extra.username) {
            this.setState({ userId: extra.username })
        }

        this.fetchRoleMasterEmployee();
    }

    fetchRoleMasterEmployee = async () => {
        const [, response] = await getRoleMasterEmployee();

        if (response) {
            this.setState({ roleMasterEmployee: response });
        }
    };

    handleChange = (idx: number, mode: String, date: Date) => {
        const formattedDate = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
        const rows = [...this.state.rows];
        if (mode == 'valid_from') {
            const item = {
                position_code: this.state.positionCode,
                position_name: this.state.positionName,
                emp_id: rows[idx].emp_id,
                emp_name: rows[idx].emp_name,
                valid_from: formattedDate,
                valid_to: rows[idx].valid_to,
                is_delegate: rows[idx].is_delegate,
                delegate_from_emp_id: rows[idx].delegate_from_emp_id,
                date_from: date,
                date_to: rows[idx].date_to
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        } else {
            const item = {
                position_code: this.state.positionCode,
                position_name: this.state.positionName,
                emp_id: rows[idx].emp_id,
                emp_name: rows[idx].emp_name,
                valid_from: rows[idx].valid_from,
                valid_to: formattedDate,
                is_delegate: rows[idx].is_delegate,
                delegate_from_emp_id: rows[idx].delegate_from_emp_id,
                date_from: rows[idx].date_from,
                date_to: date
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        }
    };

    //positionChange = (e: any) => {
    //    this.setState({
    //        positionCode: e.target.value
    //    });
    //    console.log(e.target.value)
    //}

    positionChange = (e: any, key: any) => {
        if (key == "RoleCode") {
            const positionName = this.state.roleMasterEmployee.find(value =>
                value.positioncode === e
            )

            this.setState({
                positionCode: e,
                positionName: positionName ? positionName.positionname : e
            })
        }
        else if (key == "FieldDescription") {
            var value = e.target.value
            this.setState({
                positionName: value
            })
            const rows = [...this.state.rows];
            for (var i = 0; i < this.state.rows.length; i++) {
                const item = {
                    position_code: this.state.positionCode,
                    position_name: value,
                    emp_id: rows[i].emp_id,
                    emp_name: rows[i].emp_name,
                    valid_from: rows[i].valid_from,
                    valid_to: rows[i].valid_to,
                    is_delegate: rows[i].is_delegate,
                    delegate_from_emp_id: rows[i].delegate_from_emp_id,
                    date_from: rows[i].date_from === undefined && rows[i].valid_from === "" ? undefined : new Date(formatDateYYYYMMDD(rows[i].valid_from ?? "")),
                    date_to: rows[i].date_to === undefined && rows[i].valid_to === "" ? undefined : new Date(formatDateYYYYMMDD(rows[i].valid_to ?? ""))
                };
                rows[i] = item;
                this.setState({
                    rows
                });
            }
        }
    }

    formatDMY = (dmy: string) => {
        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);
        var Datevalue = "";
        if (dmy.length < 9) {
            Datevalue = year + '-' + month + '-' + day
        } else {
            var hour = dmy.substring(8, 10);
            var second = dmy.substring(10, 12);
            var millssecond = dmy.substring(12, 14);
            Datevalue = year + '-' + month + '-' + day + ' ' + hour + ':' + second + ':' + millssecond
        }

        return Datevalue;
    }

    handleAddRow = () => {
        const item = {
            position_code: "",
            position_name: "",
            emp_id: "",
            emp_name: "",
            valid_from: `${validDateFrom.getFullYear()}${(validDateFrom.getMonth() + 1).toString().padStart(2, '0')}${validDateFrom.getDate().toString().padStart(2, '0')}`,
            valid_to: `${validDateTo.getFullYear()}${(validDateTo.getMonth() + 1).toString().padStart(2, '0')}${validDateTo.getDate().toString().padStart(2, '0')}`,
            is_delegate: "",
            delegate_from_emp_id: "",
            date_from: new Date(),
            date_to: new Date('9999/12/31')
        };
        const itemSelect = {
            code: "",
            name: "",
            display: ""
        };
        this.setState({
            rows: [...this.state.rows, item],
            valueSelectUse: [...this.state.valueSelectUse, itemSelect]
        });
    };

    handleRemoveSpecificRow = (name: string, idx: number) => () => {

        const rows = [...this.state.rows]
        const valueSelectUse = [...this.state.valueSelectUse];
        rows.splice(idx, 1)
        valueSelectUse.splice(idx, 1)
        this.setState({ rows, valueSelectUse })
    }

    public handlechageAutocomplete(idx: number, value: any) {
        const rows = [...this.state.rows];
        const valueSelectUse = [...this.state.valueSelectUse];
        const item = {
            position_code: this.state.positionCode,
            position_name: this.state.positionName,
            emp_id: value[0].code,
            emp_name: value[0].name,
            valid_from: rows[idx].valid_from,
            valid_to: rows[idx].valid_to,
            is_delegate: rows[idx].is_delegate,
            delegate_from_emp_id: rows[idx].delegate_from_emp_id,
            date_from: rows[idx].date_from === undefined && rows[idx].valid_from === "" ? undefined : new Date(formatDateYYYYMMDD(rows[idx].valid_from ?? "")),
            date_to: rows[idx].date_to === undefined && rows[idx].valid_to === "" ? undefined : new Date(formatDateYYYYMMDD(rows[idx].valid_to ?? ""))
        };
        const itemSelect = {
            code: value[0].code,
            name: value[0].name,
            display: value[0].display
        };
        rows[idx] = item;
        valueSelectUse[idx] = itemSelect;
        this.setState({
            rows, valueSelectUse
        });
    }

    onsubmit = () => {
        const duplicatedUsers = this.getDuplicatedSelectUsers();
        if (duplicatedUsers.length > 0) {
            Swal.fire(
                'ไม่สำเร็จ',
                renderToStaticMarkup(
                    <div>
                        <p>มีการเลือกผู้ใช้งานซ้ำ</p>
                        <br />
                        <ul className="list-disc text-left text-sm">
                            {duplicatedUsers.map(user => (
                                <li key={user}>{user}</li>
                            ))}
                        </ul>
                    </div>
                ),
                'error'
            );

            return;
        }

        if (this.state.positionCode == '' || this.state.rows[0]['emp_id'] == "") {
            Swal.fire('ไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ !', 'warning')
        }
        else if (this.state.positionName == '') {
            Swal.fire('ไม่สำเร็จ', 'กรุณากรอก Field Description', 'warning')
        }
        else if (this.state.isCreate) {
            this.setState({
                isCreate: false
            })
            this.props.requestCreatePositionemployee(true, "POST", this.state.userId, this.state.rows, this.state.company)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        }
    }

    getDuplicatedSelectUsers = () => {
        type UserOption = typeof initSelect;

        const userMap = new Map((this.props.conditionSearch.response?.result_list || []).map((user: UserOption) => [user.code, user]));
        const selectedUsers = (this.state.valueSelectUse as UserOption[]).filter((user: UserOption) => userMap.has(user.code));

        // Temporary dict that's hold seen users
        const seenUsers = new Map<string, UserOption>();
        // This array will hold duplicated users
        const duplicatedUsers: string[] = [];

        // Check if user is duplicated or not, if yes add to `duplicatedUsers`
        selectedUsers.forEach(user => {
            const seenUser = seenUsers.get(user.code);
            if (seenUser) {
                duplicatedUsers.push(seenUser.display);
            } else {
                seenUsers.set(user.code, userMap.get(user.code)!);
            }
        });

        return duplicatedUsers;
    };

    componentDidUpdate(nextProps: any, nextState: any) {
        const { statusCreateEmployee, isLoadCreateEmployee, statusSearch, responseSearch, messageCreateEmployee, isLoad } = this.props;
        if (statusCreateEmployee == 200 && isLoadCreateEmployee) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'เพิ่มข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    this.props.requestCreatePositionemployee(true, "CLEAR", this.state.userId, this.state.rows, this.state.company)
                    //window.location.href = "/roleemployee"
                    this.props.history.push('/roleemployee')
                }
            })
        } else if (statusCreateEmployee != 200 && isLoadCreateEmployee) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            if (messageCreateEmployee) {
                var msg = JSON.parse(messageCreateEmployee);
                Swal.fire({
                    title: 'ไม่สำเร็จ!',
                    text: msg.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then((result) => {
                    this.props.requestCreatePositionemployee(true, "CLEAR", this.state.userId, this.state.rows, this.state.company)
                })
            }
        }
        if (isLoad && statusSearch == 200 && this.state.isready == false) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var searchData = responseSearch!['result_list']
            if (searchData != null) {
                //console.log(searchData)
                const rows = [...this.state.rows];
                const valueSelectUse = [...this.state.valueSelectUse];
                for (var i = 0; i < responseSearch!['result_list'].length; i++) {
                    const item = {
                        position_code: searchData![i]['position_code'],
                        position_name: searchData![i]['position_name'],
                        emp_id: searchData![i]['emp_id'],
                        emp_name: searchData![i]['emp_name'],
                        valid_from: searchData![i]['valid_from'],
                        valid_to: searchData![i]['valid_to'],
                        is_delegate: searchData![i]['is_delegate'],
                        delegate_from_emp_id: searchData![i]['delegate_from_emp_id'],
                        date_from: searchData![i]['date_from'] === undefined && searchData![i]['valid_from'] === "" ? undefined : new Date(formatDateYYYYMMDD(searchData![i]['valid_from'] ?? "")),
                        date_to: searchData![i]['date_to'] === undefined && searchData![i]['valid_to'] === "" ? undefined : new Date(formatDateYYYYMMDD(searchData![i]['valid_to'] ?? ""))
                    };
                    const itemSelect = {
                        code: searchData![i]['emp_id'],
                        name: searchData![i]['emp_name'],
                        display: searchData![i]['emp_name']
                    };

                    rows[i] = item;
                    valueSelectUse[i] = itemSelect
                    // this.state.rows[i] = item
                    this.setState({
                        rows, valueSelectUse
                    })
                    if (this.state.rows.length != 0) {
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                    }
                }
            }
            //console.log(this.state.rows)
            this.setState({
                isready: true
            })
        } else if (isLoad && statusSearch == 204 && responseSearch == null) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่มีข้อมูล!',
                text: 'ไม่มีข้อมูล',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    this.props.requestsearchemployee(true, "CLEAR", '', '')
                    this.props.history.push('/roleemployee')
                }
            })
        }
        var company = this.props.company
        if (company != this.state.company) {
            this.setState({
                company: company
            });
        }
    }

    back() {
        this.props.history.push('/roleemployee')
    }

    componentWillUnmount() {
        this.props.requestCreatePositionemployee(true, "CLEAR", this.state.userId, this.state.rows, this.state.company)
        this.props.requestsearchemployee(this.state.isready, "CLEAR", "", "")
        this.setState({
            rows: [inititem],
            autocomplete: [],
            positionCode: '',
            positionName: '',
            isready: true,
            responsee: '',
            formdata: [],
            isCreate: true
        })
    }

    range(start: number, end: number, step: number = 1): number[] {
        const result: number[] = [];
        for (let i = start; i <= end; i += step) {
            result.push(i);
        }
        return result;
    }


    render() {
        console.log(this.state.rows)

        function mapRoleMasterArrayToOptionArray(arr: RoleMasterEmployeeResponse[]): Opstion[] {
            let response: Opstion[] = []
            if (arr) {
                response = arr.map(item => ({
                    value: item.positioncode,
                    label: item.positioncode + `(${item.positionname})`
                }));
            }

            return response
        }

        const option = mapRoleMasterArrayToOptionArray(this.state.roleMasterEmployee && this.state.roleMasterEmployee)

        const years: number[] = this.range(1990, getYear(new Date()) + 1, 1);
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        console.log(this.state.positionCode)
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            Role Employee
                        </span>
                    </div>
                    <div className="mat-box">
                        <Row >
                            <Col>
                                <div className="container">
                                    <div>
                                        <div className='trapezoid trapezoid-purple' style={{ width: 'auto', display: "inline-block" }}>
                                            <span>
                                                Role Code : {this.state.positionCode}
                                            </span>
                                        </div>
                                        <div style={{ display: "inline", float: "right" }}>
                                            <Link to="/roleemployee">
                                                <Button variant="success" size="sm"><FaArrowCircleLeft />&nbsp;Back</Button>
                                            </Link>
                                            {/*<Button className="mx-2" variant="info" size="sm" onClick={this.onsubmit}><FaPlusCircle />&nbsp;Save</Button>*/}
                                        </div>
                                    </div>
                                    <br />
                                    {/*<InputGroup>*/}
                                    {/*    <InputGroupAddon addonType="prepend">*/}
                                    {/*        <InputGroupText>Role Code</InputGroupText>*/}
                                    {/*    </InputGroupAddon>*/}
                                    {/*    <Input placeholder="Role Code" type="text"*/}
                                    {/*        value={this.state.positionCode}*/}
                                    {/*        readOnly={this.state.checkReadonly}*/}
                                    {/*        onChange={this.positionChange} />*/}
                                    {/*</InputGroup>*/}
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Role Code</InputGroupText>
                                        </InputGroupAddon>
                                        {/* <Input placeholder="Role Code" type="text"
                                            value={this.state.positionCode}
                                            readOnly={this.state.checkReadonly}
                                            onChange={(e) => this.positionChange(e, 'RoleCode')} /> */}
                                        {/* <Input
                                            placeholder="Role Code"
                                            type="text"
                                            value={this.state.positionCode}
                                            disabled={this.state.checkReadonly}
                                            onChange={(e) => {
                                                e.persist()
                                                this.positionChange(e, 'RoleCode')
                                            }}
                                        >
                                            <option selected>Please choose</option>
                                            {this.state.roleMasterEmployee && this.state.roleMasterEmployee.map((value, index) => (
                                                <option key={index} value={`${value.positioncode}`}>{value.positioncode}{' '}({value.positionname})</option>
                                            ))}
                                        </Input> */}
                                        {this.state.positionCode !== '' ?
                                            <Input placeholder="Role Code" type="text"
                                                value={this.state.positionCode}
                                                readOnly={this.state.checkReadonly} />
                                            :
                                            <CreatableSelect
                                                isClearable
                                                options={option}
                                                onChange={({ value, label }) => {
                                                    this.positionChange(value, 'RoleCode')
                                                }}
                                                isDisabled={this.state.checkReadonly}
                                                value={this.state.positionCode}
                                                className='w-4/5'
                                            />
                                        }
                                    </InputGroup>
                                    <br />
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Field Description</InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="Field Description" type="text"
                                            value={this.state.positionName}
                                            onChange={(e) => this.positionChange(e, 'FieldDescription')} />
                                    </InputGroup>
                                    <br />
                                    <Table className="table-bordered table-sm" hover size="sm">
                                        <thead>
                                            <tr className='text-center thead-light' style={{ width: '100%' }}>
                                                <th style={{ width: '5%', textAlignLast: "center" }}>#</th>
                                                <th style={{ width: '45%' }}>Username</th>
                                                <th style={{ width: '25%' }}>Valid Form</th>
                                                <th style={{ width: '25%' }}>Valid to</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.rows.map((item, idx) => (
                                                <tr id={"addr" + idx} key={idx}>
                                                    <td className='text-center'><Button className="mx-2" variant="danger" size="sm" onClick={this.handleRemoveSpecificRow("addr" + idx, idx)}><FaTrashAlt /></Button></td>
                                                    <td>
                                                        <Search
                                                            handlechageAutocomplete={this.handlechageAutocomplete.bind(this, idx)}
                                                            valueSearch={this.state.valueSelectUse[idx]}
                                                            app_id={this.state.company}
                                                        />
                                                    </td>
                                                    <td>
                                                        {/* <Form.Control type="text" value={this.state.rows[idx].valid_from} /> */}
                                                        {/* <DatePicker
                                                            wrapperClassName="w-full"
                                                            className='w-full'
                                                            selected={this.state.rows[idx].date_from}
                                                            onChange={(date) => {
                                                                this.handleChange(idx, 'valid_from', date!)
                                                            }}
                                                            customInput={<Form.Control className="menu-form custom-datepicker" />}
                                                            dateFormat="dd/MM/yyyy"
                                                        /> */}
                                                        <DatePicker
                                                            wrapperClassName="w-full"
                                                            className='w-full'
                                                            selected={this.state.rows[idx].valid_from.length < 8 ? undefined : this.state.rows[idx].date_from}
                                                            onChange={(date) => {
                                                                this.handleChange(idx, 'valid_from', date!)
                                                            }}
                                                            customInput={<Form.Control className="menu-form custom-datepicker" />}
                                                            dateFormat="dd/MM/yyyy"
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled,
                                                            }) => (
                                                                <div
                                                                    style={{
                                                                        margin: 10,
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                                        {"<"}
                                                                    </button>
                                                                    <select
                                                                        value={getYear(date)}
                                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                                    >
                                                                        {years.map((option) => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <select
                                                                        value={months[getMonth(date)]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(months.indexOf(value))
                                                                        }
                                                                    >
                                                                        {months.map((option) => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                                        {">"}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        {/* <Form.Control type="text" value={this.state.rows[idx].valid_to} /> */}
                                                        {/* <DatePicker
                                                            wrapperClassName="w-full"
                                                            className="w-full"
                                                            selected={this.state.rows[idx].date_to}
                                                            onChange={(date) => {
                                                                this.handleChange(idx, 'valid_to', date!)
                                                            }}
                                                            customInput={<Form.Control className="menu-form custom-datepicker" />}
                                                            dateFormat="dd/MM/yyyy"
                                                        /> */}
                                                        <DatePicker
                                                            wrapperClassName="w-full"
                                                            className="w-full"
                                                            selected={this.state.rows[idx].valid_to.length < 8 ? undefined : this.state.rows[idx].date_to}
                                                            onChange={(date) => {
                                                                this.handleChange(idx, 'valid_to', date!)
                                                            }}
                                                            customInput={<Form.Control className="menu-form custom-datepicker" />}
                                                            dateFormat="dd/MM/yyyy"
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled,
                                                            }) => (
                                                                <div
                                                                    style={{
                                                                        margin: 10,
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                                        {"<"}
                                                                    </button>
                                                                    <select
                                                                        value={getYear(date)}
                                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                                    >
                                                                        {years.map((option) => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <select
                                                                        value={months[getMonth(date)]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(months.indexOf(value))
                                                                        }
                                                                    >
                                                                        {months.map((option) => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                                        {">"}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className='text-center'><Button className="mx-2" color="primary" size="sm" onClick={this.handleAddRow}><FaPlusCircle /></Button></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ display: "inline", float: "right", paddingTop: "10px" }}>
                                    <Button className="mx-2" color="primary" size="sm" onClick={this.onsubmit}><FaPlusCircle />&nbsp;Save</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.appState,
        ...state.searchEmployee,
        ...state.createEmployee,
        ...state.resCreateEmployee,
        conditionSearch: state.conditionSearch
    }),
    ({
        ...SearchEmployeeStore.actionCreators,
        ...CreateEmployeeStore.actionCreators,
        ...AppState.actionCreators,
        ...ConditionStore.actionCreators
    })
)(AddPositionEmployee)